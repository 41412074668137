/**
 * For a given Maybe<string>, get the actual character at the given index.
 * NOTE: This is not the same as `str[idx]` or `str.charAt(idx)` or similar
 *   operations. These other operations will not handle multiple character code
 *   unicode strings properly. By using spread to iterate the string, we get the
 *   actual characters. This operation is MUCH slower than the simple operations
 *   like `str[idx]`, and so should not be the defacto method unless support for
 *   compound symbols is required.
 * This method always returns a string
 * @param str String to extract the character from
 * @param idx Index of the character to extract
 */
export const charAtIdx = (str: Maybe<string>, idx: number): string => (str && [...str][idx]) || ''

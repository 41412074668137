/**
 * Convenience method for testing a condition is truthy when adding a value to
 * an array. If only a single arg is passed, the truthiness of the arg will be
 * used as the condition.
 *
 * Example:
 * ```ts
 * const b = 'b'
 *
 * // Condition check
 * const condition1 = true
 * const condition2 = false
 * const array1 = ['a', ...pushIf(condition1, b)] // ['a', 'b']
 * const array2 = ['a', ...pushIf(condition2, b)] // ['a']
 *
 * // Value-only
 * const array3 = ['a', ...pushIf(b)] // ['a', 'b']
 * const array4 = ['a', ...pushIf('')] // ['a']
 * ```
 */
export function pushIf<T>(value: T | false | null | undefined | '' | 0): T[]
export function pushIf<T>(condition: any, value: T): T[]
export function pushIf<T>(...args: any[]): T[] {
	let condition
	let value

	if (args.length === 1) {
		condition = value = args[0]
	} else {
		condition = args[0]
		value = args[1]
	}

	return condition ? [value] : []
}

import { useMemo } from 'react'
import { useChgState } from './useChgState'

/**
 * Similar to `useToggleState`, but without the toggle fn since it isn't used
 * particularly frequently.
 * @see useToggleState
 */
export const useBoolState = (
	initValue: boolean,
): [state: boolean, setTrue: () => void, setFalse: () => void] => {
	const [state, setState] = useChgState(initValue)
	const [toggleTrue, toggleFalse] = useMemo(
		() => [
			/* toggleTrue */
			() => setState(true),
			/* toggleFalse */
			() => setState(false),
		],
		[],
	)

	return [state, toggleTrue, toggleFalse]
}

/* istanbul ignore file */

const resolvedPromise = Promise.resolve()

/**
 * Simple function to make a task function async. If it's not instantly clear,
 * this is different from `Promise.resolve(fn)`. Whereas `Promise.resolve(fn)`
 * runs `fn` synchronously, `Promise.resolve().then(fn)`, runs `fn` on the next
 * "tick" of the event loop (as a microtask that runs before timeouts).
 *
 * This would be used simply to make a function async.
 *
 * @param task
 */
export const microtask = <T extends () => any>(task: T): Promise<ReturnType<T>> =>
	resolvedPromise.then(task)

export type Curried1<T1, R> = {
	(): Curried1<T1, R>
	(t1: T1): R
}

export type Curried2<T1, T2, R> = {
	(): Curried2<T1, T2, R>
	(t1: T1): Curried1<T2, R>
	(t1: T1, t2: T2): R
}

export type Curried3<T1, T2, T3, R> = {
	(): Curried3<T1, T2, T3, R>
	(t1: T1): Curried2<T2, T3, R>
	(t1: T1, t2: T2): Curried1<T3, R>
	(t1: T1, t2: T2, t3: T3): R
}

export type Curried4<T1, T2, T3, T4, R> = {
	(): Curried4<T1, T2, T3, T4, R>
	(t1: T1): Curried3<T2, T3, T4, R>
	(t1: T1, t2: T2): Curried2<T3, T4, R>
	(t1: T1, t2: T2, t3: T3): Curried1<T4, R>
	(t1: T1, t2: T2, t3: T3, t4: T4): R
}

export type Curried5<T1, T2, T3, T4, T5, R> = {
	(): Curried5<T1, T2, T3, T4, T5, R>
	(t1: T1): Curried4<T2, T3, T4, T5, R>
	(t1: T1, t2: T2): Curried3<T3, T4, T5, R>
	(t1: T1, t2: T2, t3: T3): Curried2<T4, T5, R>
	(t1: T1, t2: T2, t3: T3, t4: T4): Curried1<T5, R>
	(t1: T1, t2: T2, t3: T3, t4: T4, t5: T5): R
}

export type Curried6<T1, T2, T3, T4, T5, T6, R> = {
	(): Curried6<T1, T2, T3, T4, T5, T6, R>
	(t1: T1): Curried5<T2, T3, T4, T5, T6, R>
	(t1: T1, t2: T2): Curried4<T3, T4, T5, T6, R>
	(t1: T1, t2: T2, t3: T3): Curried3<T4, T5, T6, R>
	(t1: T1, t2: T2, t3: T3, t4: T4): Curried2<T5, T6, R>
	(t1: T1, t2: T2, t3: T3, t4: T4, t5: T5): Curried1<T6, R>
	(t1: T1, t2: T2, t3: T3, t4: T4, t5: T5, t6: T6): R
}

export function curry<T1, R>(fn: (t1: T1) => R): Curried1<T1, R>
export function curry<T1, T2, R>(fn: (t1: T1, t2: T2) => R): Curried2<T1, T2, R>
export function curry<T1, T2, T3, R>(fn: (t1: T1, t2: T2, t3: T3) => R): Curried3<T1, T2, T3, R>
export function curry<T1, T2, T3, T4, R>(
	fn: (t1: T1, t2: T2, t3: T3, t4: T4) => R,
): Curried4<T1, T2, T3, T4, R>
export function curry<T1, T2, T3, T4, T5, R>(
	fn: (t1: T1, t2: T2, t3: T3, t4: T4, t5: T5) => R,
): Curried5<T1, T2, T3, T4, T5, R>
export function curry<T1, T2, T3, T4, T5, T6, R>(
	fn: (t1: T1, t2: T2, t3: T3, t4: T4, t5: T5, t6: T6) => R,
): Curried6<T1, T2, T3, T4, T5, T6, R>

export function curry(fn: (...args: any[]) => any) {
	const arity = fn.length

	if (arity === 0) return fn

	return (...args: any[]) => {
		const len = args.length

		if (len === 0) return fn

		// NOTE: These switches are ugly but they exist for a reason. They can
		//  dramatically speed up the most common cases (arity < 5) calls b/c
		//  Function#apply is much slower than either direct calls or Function#call.
		//  So, it's ugly, but for utility code, it's fine. Knowing this, it is
		//  strange that browser Function#apply implementations don't use a
		//  similar heuristic.
		if (len >= arity) {
			switch (arity) {
				case 1:
					return fn(args[0])

				case 2:
					return fn(args[0], args[1])

				case 3:
					return fn(args[0], args[1], args[2])

				case 4:
					return fn(args[0], args[1], args[2], args[3])

				case 5:
					return fn(args[0], args[1], args[2], args[3], args[4])

				default:
					return fn(...args)
			}
		}

		let bound: any

		switch (len) {
			case 1:
				bound = fn.bind(null, args[0])
				break

			case 2:
				bound = fn.bind(null, args[0], args[1])
				break

			case 3:
				bound = fn.bind(null, args[0], args[1], args[2])
				break

			case 4:
				bound = fn.bind(null, args[0], args[1], args[2], args[3])
				break

			default:
				bound = fn.bind(null, ...args)
				break
		}

		return curry(bound)
	}
}

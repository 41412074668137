import { useMemo } from 'react'
import { useChgState } from './useChgState'

type UseToggleState = [
	state: boolean,
	toggleState: () => void,
	toggleOn: () => void,
	toggleOff: () => void,
]

/**
 * Manages bool toggle state
 */
export const useToggleState = (initValue: boolean): UseToggleState => {
	const [state, setState] = useChgState(initValue)
	const [toggleState, toggleOn, toggleOff] = useMemo(
		() => [
			/* toggleState */
			() => setState((prevState) => !prevState),
			/* toggleOn */
			() => setState(true),
			/* toggleOff */
			() => setState(false),
		],
		[],
	)

	return [state, toggleState, toggleOn, toggleOff]
}

import debounce from 'lodash/debounce'
import { useEffect, useRef } from 'react'
import type { DebounceOpts, GetDebounceReturn } from '../types'
import { useConstant } from './useConstant'

const normalizeOpts = (opts?: DebounceOpts) => ({
	leading: false,
	trailing: true,
	...opts,
})

/**
 * Immutable wrapped `debounce` hook.
 * NOTE: This refines type for lodash/debounce to use correct return type if
 *  { leading: true } (Default: false)
 * @see debounce
 */
export const useDebounce = <
	T extends (...args: any[]) => any,
	Opts extends DebounceOpts = DebounceOpts,
>(
	fn: T,
	wait?: number,
	opts?: Opts,
): GetDebounceReturn<T, Opts> => {
	const fnRef = useRef(fn)
	fnRef.current = fn

	const debounced = useConstant(() =>
		debounce((...args: any[]) => fnRef.current(...args), wait, normalizeOpts(opts)),
	)

	useEffect(
		() => () => {
			// Don't fire debounced function when unmounted
			debounced.cancel()
		},
		[],
	)

	// NOTE: Casting as `any` b/c I don't want to deal w/ implementing the entire
	//  debounce signature just to tell TypeScript that what we're returning is
	//  the same.
	return useRef(debounced).current as any
}

// Testing iOS scroll, this value works best for firing debounce efficiently
// when the URL bar hides.
export const IOS_DEBOUNCE_SCROLL = 85

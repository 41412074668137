import { useCallback, useEffect, useRef } from 'react'

export type UseTimeout = [
	setTimeout: (fn: () => void, interval?: number) => void,
	clearTimeout: () => void,
]

/**
 * Creates a singleton setTimeout that knows about its timeoutId. Returns
 * a `setTimeout` that clears its previous timeout, and a `clearTimeout`
 * that clears the previous timeout. Basically, binds `setTimeout` to a
 * particular call, so you don't have to keep track of timeout ids.
 *
 * NOTE: This also handles clearing timeouts when unmounted. Calls to
 *  `clearTimeout` will always fire (although it's superfluous to do so when
 *  unmounting), but `setTimeout` calls will not fire when unmounted.
 */
export const useTimeout = (): UseTimeout => {
	const timeoutId = useRef(-1)

	const clearTimeout = useCallback(() => {
		window.clearTimeout(timeoutId.current)
	}, [])

	const setTimeout = useCallback((fn: () => void, interval?: number) => {
		clearTimeout()
		timeoutId.current = window.setTimeout(fn, interval)
	}, [])

	useEffect(() => clearTimeout, [])

	return [setTimeout, clearTimeout]
}

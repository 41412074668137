/* istanbul ignore file */

/**
 * Allows for compilation type check of exhaustive switch statements.
 *
 * @example
 * ```ts
 * function getColorName(c: Color): string {
 *   switch(c) {
 *     case Color.Red:
 *       return 'red';
 *     case Color.Green:
 *       return 'green';
 *   }
 *
 *   return assertUnreachable(c);
 * }
 * ```
 *
 * This code will throw a compilation error if the enum Color has more entries
 * other than Red and Green.
 *
 * @param x
 * @param message
 */
export const assertUnreachable = (x: never, message: string): never => {
	throw new Error(message)
}

export const safeAssertUnreachable = <R>(x: never, message: string, unknownDefault: R) => {
	console.error(new Error(message))
	return unknownDefault
}

import { useMemo, useRef } from 'react'

/**
 * Like `useStateGetter`, but doesn't update state. A simple wrapper around
 * refs to allow them to be used as getter / setter functions, which can be
 * more convenient to work with in some cases.
 *
 * @see useStateGetter
 */
export const useRefGetter = <T>(initValue: T): [get: () => T, set: (v: T) => void] => {
	const valueRef = useRef(initValue)

	return useMemo(() => [() => valueRef.current, (v) => (valueRef.current = v)], [])
}

import isEqual from 'react-fast-compare'

const hasOwn = Object.prototype.hasOwnProperty

/**
 * Performs a ref -> obj diff. This isn't a two-way diff, so only values in
 * ref are checked. A two-way diff isn't that difficult, but because most of
 * the expense comes in the `isEqual` check, we'd have to create a Set of
 * checked keys so we don't check anything twice. Since currently a two-way
 * diff isn't required, I'm omitting it.
 *
 * This will return an object containing the values from `obj[k]` that are
 * different from `ref[k]` as long as both objects have `k`.
 *
 * @param ref Reference object
 * @param obj Change / diff object.
 */
export const diff = <T extends Record<any, any>>(ref: T, obj: Record<any, any>): Partial<T> => {
	if (Array.isArray(ref)) {
		throw new TypeError(`diff only supports plain objects`)
	}

	const diffObj: Partial<T> = {}

	for (const k in ref) {
		if (hasOwn.call(ref, k)) {
			const vRef = ref[k]
			const vObj = obj[k]

			if (!isEqual(vRef, vObj)) {
				diffObj[k] = vObj
			}
		}
	}

	return diffObj
}

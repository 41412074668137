import type { UseGetState, UseSetState } from './useStateGetter'
import { useStateGetter } from './useStateGetter'

/**
 * Same thing as `useState`, but only updates when state changes. In most cases
 * this hook is completely unnecessary, because calling `setState` with an
 * unchanged value will not cause a re-render. This is true for example within
 * any hooks (useCallback, useEffect). But when `setState` is called async,
 * current behavior is to re-render, even when values haven't changed.
 *
 * This implementation uses `useStateGetter`, since that already implements
 * strict equality checks for updating.
 */
export const useChgState = <T>(initValue: T | UseGetState<T>): [T, UseSetState<T>] => {
	const [getState, setState] = useStateGetter(initValue)

	return [getState(), setState]
}

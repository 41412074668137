import { useEffect } from 'react'
import type { EventListerOpts } from '../types'
import { PASSIVE } from '../types'
import { useConstant } from './useConstant'
import { useFn } from './useFn'

export const useWindowEvent = <K extends keyof WindowEventMap>(
	type: K,
	handler: (ev: WindowEventMap[K]) => any | void,
	opts: EventListerOpts = PASSIVE,
	enabled = true,
) => {
	type = useConstant(() => type)
	handler = useFn(handler)
	opts = useConstant(() => opts)

	useEffect(() => {
		// If event is toggled off, clean up and return
		if (!enabled) return window.removeEventListener(type, handler, opts)

		window.addEventListener(type, handler, opts)

		return () => window.removeEventListener(type, handler, opts)
	}, [enabled])
}

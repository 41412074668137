export type EventListerOpts = AddEventListenerOptions | boolean

// Capture only, for when we want to stop prop on the event itself
export const CAPTURE: EventListerOpts = {
	capture: true,
}

// Passive only, for when other events need to stop prop (DEFAULT for any related hooks)
export const PASSIVE: EventListerOpts = {
	passive: true,
}

// Useful constant for fast events (events that aren't intercepted w/ preventDefault / stopProp)
export const PASSIVE_CAPTURE: EventListerOpts = {
	...CAPTURE,
	...PASSIVE,
}

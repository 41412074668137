/* istanbul ignore file */
import isFunction from 'lodash/isFunction'

export function resolveState<S>(state: S | (() => S)): S
export function resolveState<S>(state: S | ((prevState: S) => S), prevState: S): S
export function resolveState<S>(...args: any[]): S {
	const stateOrFn = args[0]

	return isFunction(stateOrFn) ? (args.length === 1 ? stateOrFn() : stateOrFn(args[1])) : stateOrFn
}

import throttle from 'lodash/throttle'
import { useEffect, useRef } from 'react'
import type { GetThrottleReturn, ThrottleOpts } from '../types'
import { useConstant } from './useConstant'

const normalizeOpts = (opts?: ThrottleOpts): Required<ThrottleOpts> => ({
	leading: true,
	trailing: false,
	...opts,
})

/**
 * Immutable wrapped `throttle` hook.
 * NOTE: This refines type for lodash/throttle to use correct return type if
 *  { leading: true } (Default: true).
 * @see lodash/throttle
 */
export const useThrottle = <
	T extends (...args: any[]) => any,
	Opts extends ThrottleOpts = ThrottleOpts,
>(
	fn: T,
	wait?: number,
	opts?: Opts,
): GetThrottleReturn<T, Opts> => {
	const fnRef = useRef(fn)
	fnRef.current = fn

	const throttled = useConstant(() =>
		throttle((...args: any[]) => fnRef.current(...args), wait, normalizeOpts(opts)),
	)

	useEffect(
		() => () => {
			// Don't fire throttled function when unmounted
			throttled.cancel()
		},
		[],
	)

	// NOTE: Casting as `any` b/c I don't want to deal w/ implementing the entire throttle
	//  signature just to tell TypeScript that what we're returning is the same.
	return throttled as any
}

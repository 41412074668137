import type { Dispatch, SetStateAction } from 'react'
import { useMemo, useRef, useState } from 'react'
import { resolveState } from '../utils'

export type UseGetState<T> = () => T
export type UseSetState<T> = Dispatch<SetStateAction<T>>

/**
 * Always up-to-date state values using getter / setter.
 */
export const useStateGetter = <T>(
	initValue: T | UseGetState<T>,
): [UseGetState<T>, UseSetState<T>] => {
	// This is only used to resolve the initial state according to the same spec
	// as `useState`. We could use conditional and various hooks to do this
	// ourselves, but this is far simpler. Without this, we need to make sure to
	// only call the `useState` factory on first mount. That means we need to
	// check if the component both is mounted, as was not mounted on the last
	// render. In addition, we'd need a `useUpdate` or similar to trigger a
	// re-render. By using a local state reflection, we can both resolve the
	// initial state correctly, and trigger a re-render by setting it.
	const [_resolvedState, _setResolvedState] = useState(initValue)
	const stateRef = useRef<T>(_resolvedState)
	stateRef.current = _resolvedState

	return useMemo(
		() => [
			() => stateRef.current,

			(next) => {
				const currV = stateRef.current
				const nextV = resolveState(next, currV)

				if (currV === nextV) return

				stateRef.current = nextV
				_setResolvedState(nextV)
			},
		],
		[],
	)
}

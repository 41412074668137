import isEmpty from 'lodash/isEmpty'

// NOTE: An empty immutable set can be reused forever once it's created for
//  reasons that should be obvious. This is a simple, minor, memory / perf
//  optimization.

let EmptySet: ImmutSet<any>

export class ImmutSet<T> {
	constructor(init?: Iterable<T>) {
		if (!init || isEmpty(init)) {
			if (EmptySet) return EmptySet

			EmptySet = this
		}

		this._base = new Set(init)
	}

	private readonly _base: ReadonlySet<T>;

	[Symbol.toStringTag] = 'ImmutSet'

	get size(): number {
		return this._base.size
	}

	add(value: T): ImmutSet<T> {
		if (this.has(value)) return this

		return new ImmutSet([...this._base, value])
	}

	clear(): ImmutSet<T> {
		return this.size === 0 ? this : new ImmutSet<T>()
	}

	delete(value: T): ImmutSet<T> {
		// Empty set or can't delete
		if (this.size === 0 || !this.has(value)) return this

		// Empty set: Has value, but we're about to remove it
		if (this.size === 1) return new ImmutSet<T>()

		const clone = new Set(this._base)

		clone.delete(value)

		return new ImmutSet(clone)
	}

	has(value: any): boolean {
		return this._base.has(value)
	}

	forEach(cb: (value: T, value2: T, set: ReadonlySet<T>) => void, thisArg?: any) {
		for (const value of this) {
			cb.call(thisArg, value, value, this._base)
		}
	}

	filter(cb: (value: T, value2: T, set: ReadonlySet<T>) => any, thisArg?: any): ImmutSet<T> {
		// Empty set
		if (!this.size) return this

		const filtered: T[] = []

		for (const value of this) {
			if (cb.call(thisArg, value, value, this._base)) {
				filtered.push(value)
			}
		}

		// Empty set: Everything filtered out
		if (filtered.length === 0) return new ImmutSet<T>()

		// Same set: Nothing changed
		if (filtered.length === this.size) return this

		return new ImmutSet(filtered)
	}

	// map<U>(cb: (value: T, value2: T, set: ReadonlySet<T>) => U, thisArg?: any): ImmutSet<U> {
	// 	if (!this.size) return this as ImmutSet<any>
	//
	// 	const mapped: U[] = []
	//
	// 	for (const value of this) {
	// 		mapped.push(cb.call(thisArg, value, value, this._base))
	// 	}
	//
	// 	if (mapped.length === 0) return new ImmutSet()
	//
	// 	return new ImmutSet(mapped)
	// }

	[Symbol.iterator]() {
		return this.values()
	}

	entries() {
		return this._base.entries()
	}

	keys() {
		return this._base.keys()
	}

	values() {
		return this._base.values()
	}
}

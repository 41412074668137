import random from 'lodash/random'
import { useCallback, useEffect, useRef } from 'react'
import type { Selector } from 'react-redux'
import { useSelector } from 'react-redux'
import { useBoolState } from './useBoolState'
import { useConstant } from './useConstant'
import { useFn } from './useFn'
import { useInterval } from './useInterval'
import { usePrevious } from './usePrevious'
import { useStateGetter } from './useStateGetter'

export type PollingInterval = ValueUnion<typeof Polling>
export const Polling = {
	FAST: 5_000, // 5s
	NORMAL: 30_000, // 30s
	LONG: 120_000, // 2m
	VERY_LONG: 360_000, // 6m
} as const

export type PollingFn<T> = (arg: T) => void

type GetFnArgs<R> = (immediate: boolean) => R

type UsePolling = [pausePolling: () => void, resumePolling: () => void, isPolling: boolean]

const useTimeouts = () => {
	const timeoutIds = useConstant(() => new Set<number>())

	const setTimeout = useCallback((fn: () => void, ms = 0) => {
		const timeoutId = window.setTimeout(() => {
			timeoutIds.delete(timeoutId)
			fn()
		}, ms)

		timeoutIds.add(timeoutId)
	}, [])

	const clearTimeouts = useCallback(() => {
		timeoutIds.forEach(window.clearTimeout)
	}, [])

	useEffect(() => clearTimeouts, [])

	return [setTimeout, clearTimeouts] as const
}

/**
 * Factory to create a usePolling hook
 * @param shouldPoll$ Selector that should return whether we should poll
 * @param getFnArgs A function that will return the args to pass to any polling
 * functions
 */
export const createUsePolling = <T extends GetFnArgs<any>>(
	shouldPoll$: Selector<any, boolean>,
	getFnArgs: T,
) => {
	const usePolling = (
		fns: PollingFn<ReturnType<T>>[],
		interval: PollingInterval | number = Polling.NORMAL,
		immediate = true,
	): UsePolling => {
		const shouldPoll = useSelector(shouldPoll$)
		const fnsRef = useRef(fns)
		fnsRef.current = fns

		const [didTriggerImmediate, setImmediateTriggered] = useBoolState(false)
		const [hasIntervalPassed, setHasIntervalPassed] = useStateGetter(immediate)
		const [isPollingLocal, resumePolling, pausePolling] = useBoolState(true)
		const [setTimeout, clearTimeouts] = useTimeouts()

		const isPolling = shouldPoll && isPollingLocal
		const shouldCancelInterval = hasIntervalPassed() && !isPolling
		const wasPolling = usePrevious(isPolling)

		const triggerPolling = useFn((isImmediate = false) => {
			if (!isPolling || !hasIntervalPassed()) return

			setHasIntervalPassed(false)

			const arg = getFnArgs(isImmediate)

			fnsRef.current.forEach((fn) => {
				if (isImmediate) return fn(arg)

				// Stagger each polling function a bit to avoid flooding the server / connection
				const timeoutMs = Math.floor(random(0, 1_000 + interval * 0.15))

				setTimeout(() => fn(arg), timeoutMs)
			})
		})

		useInterval(
			() => {
				setHasIntervalPassed(true)
				triggerPolling()
			},
			shouldCancelInterval ? null : interval,
		)

		useEffect(() => {
			if (!isPolling) return clearTimeouts()

			if (immediate && !didTriggerImmediate) {
				// Trigger if we're polling and still need to call immediate polling
				triggerPolling(true)
				setImmediateTriggered()
			} else if (wasPolling === false) {
				// Trigger if we're polling, but we weren't polling previously
				setTimeout(triggerPolling, random(250, 1000))
			}
		}, [isPolling])

		return [pausePolling, resumePolling, isPolling]
	}

	return usePolling
}

/**
 * A helper to combine multiple polling hooks into a single unified tuple. This
 * makes pause / resume / isPolling work on all the hooks. While this could
 * work when passed different tuples each render, in practice that's probably
 * not a good practice.
 *
 * Requires at least one polling tuple
 *
 * @param polling
 */
export const useCombinedPolling = (...polling: UsePolling[]): UsePolling => {
	// Some guards to help prevent misuse in development
	if (process.env.NODE_ENV === 'development' && !polling.length) {
		throw new Error('Need at least one UsePolling tuple')
	}

	const pollingRefs = useRef(polling)
	pollingRefs.current = polling

	const pause = useCallback(() => {
		pollingRefs.current.forEach(([pause]) => pause())
	}, [])

	const resume = useCallback(() => {
		pollingRefs.current.forEach(([, resume]) => resume())
	}, [])

	const isPolling = polling[0][2]

	return [pause, resume, isPolling]
}

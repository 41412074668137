import { useRef } from 'react'
import isEqual from 'react-fast-compare'

export type UseMemoEqComp<T> = (a: readonly T[], b: readonly T[]) => boolean

/**
 * Creates a memoized result when passed a factory and deps, but uses an, like
 * `React.useMemo`, but uses a deep, rather than shallow, equality comparator on
 * the deps. This implementation (using refs for both the value and deps) is
 * slightly faster than the previous implementation that used ref for just the
 * deps and then passed changed deps to `useMemo`.
 * @see useMemo
 */
export const useMemoEq = <T, D>(
	factory: () => T,
	deps: readonly D[],
	eq: UseMemoEqComp<D> = isEqual,
): T => {
	const lastValue = useRef<T>()
	const lastDeps = useRef<readonly D[]>()

	if (!lastDeps.current || !eq(deps, lastDeps.current)) {
		lastDeps.current = deps
		lastValue.current = factory()
	}

	return lastValue.current!
}

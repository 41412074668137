/* istanbul ignore file */
import { useCallback, useState } from 'react'

/**
 * Force state update, inspired by React Use.
 * @see https://github.com/streamich/react-use/blob/master/src/useUpdate.ts
 */
export const useUpdate = () => {
	const [, setState] = useState(0)

	return useCallback(() => setState((i) => i + 1), [])
}

import { useCallback, useEffect } from 'react'
import { useFn } from './useFn'
import { useRefGetter } from './useRefGetter'
import { useTimeout } from './useTimeout'

/**
 * Takes in a function and duration and returns a tuple of:
 *
 * - `isReady: () => boolean | null` // (null when it's cancelled)
 * - `clearTimeout: () => void` // (clears the timeout)
 * - `resetTimeout: () => void` // (resets the timeout)
 *
 * @see https://github.com/streamich/react-use/blob/master/src/useTimeoutFn.ts
 */
export const useTimeoutFn = (
	fn: () => void,
	ms: number,
): [isReady: () => boolean, clearTimeout: () => void, resetTimeout: () => void] => {
	const [isReady, setReady] = useRefGetter(false)
	const [setTimeout, _clearTimeout] = useTimeout()
	const fnField = useFn(fn)

	const clearTimeout = useCallback(() => {
		setReady(false)
		_clearTimeout()
	}, [])

	const resetTimeout = useCallback(() => {
		clearTimeout()

		setTimeout(() => {
			setReady(true)
			fnField()
		}, ms)
	}, [ms])

	useEffect(() => {
		resetTimeout()
		return clearTimeout
	}, [ms])

	return [isReady, clearTimeout, resetTimeout]
}

import { useEffect } from 'react'
import { useFn } from './useFn'

export const useInterval = (callback: () => void, delay?: number | null) => {
	const callbackField = useFn(callback)

	useEffect(() => {
		if (delay != null) {
			const id = window.setInterval(callbackField, delay)
			return () => window.clearInterval(id)
		}
	}, [delay])
}

/* istanbul ignore file */
// NOTE: That per the spec, initialization is much more permissive than
//  described in the TypeScript definitions. We can put this in d.ts
//  later if desired. See https://url.spec.whatwg.org/#urlsearchparams-initialize
//  Basically, as far as I can tell, parsing the values of QueryStrInit
//  just runs the values through encodeURIComponent. Tested on all major
//  browsers. This permissiveness maybe not be desired, but if it's
//  not, we should pare it down conservatively. E.g., undefined would
//  be valid also, but I omitted it from the type since we'd never have
//  a query string like `foo=undefined`
type QueryStringable = string | { toString(): string } | null

export type QueryStrInit =
	| [string, QueryStringable][]
	| Record<string, QueryStringable>
	| QueryStringable
	| URLSearchParams

/**
 * A wrapper on URLSearchParams for creating query string w/ proper encoding
 */
export const toQueryStr = (v?: QueryStrInit) => {
	const qs = new URLSearchParams(v as any).toString()

	return qs ? `?${qs}` : ''
}
